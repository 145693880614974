// Game.js
import React, { useState, useEffect } from "react";
import Board from "./Board";

const Game = () => {
  const [board, setBoard] = useState(
    Array.from({ length: 6 }, () => Array(7).fill(null))
  );
  const [player, setPlayer] = useState(1);
  const [winner, setWinner] = useState(null);

  useEffect(() => {
    const checkWinner = () => {
      for (let row = 0; row < 6; row++) {
        for (let col = 0; col < 4; col++) {
          if (
            board[row][col] &&
            board[row][col] === board[row][col + 1] &&
            board[row][col] === board[row][col + 2] &&
            board[row][col] === board[row][col + 3]
          ) {
            setWinner(board[row][col]);
            return;
          }
        }
      }

      for (let col = 0; col < 7; col++) {
        for (let row = 0; row < 3; row++) {
          if (
            board[row][col] &&
            board[row][col] === board[row + 1][col] &&
            board[row][col] === board[row + 2][col] &&
            board[row][col] === board[row + 3][col]
          ) {
            setWinner(board[row][col]);
            return;
          }
        }
      }

      for (let row = 0; row < 3; row++) {
        for (let col = 0; col < 4; col++) {
          if (
            board[row][col] &&
            board[row][col] === board[row + 1][col + 1] &&
            board[row][col] === board[row + 2][col + 2] &&
            board[row][col] === board[row + 3][col + 3]
          ) {
            setWinner(board[row][col]);
            return;
          }
        }
      }

      for (let row = 0; row < 3; row++) {
        for (let col = 3; col < 7; col++) {
          if (
            board[row][col] &&
            board[row][col] === board[row + 1][col - 1] &&
            board[row][col] === board[row + 2][col - 2] &&
            board[row][col] === board[row + 3][col - 3]
          ) {
            setWinner(board[row][col]);
            return;
          }
        }
      }

      if (board.every((row) => row.every((cell) => cell !== null))) {
        setWinner("tie");
      }
    };

    checkWinner();
  }, [board]);

  const insertCoin = (col) => {
    if (winner || board[0][col] !== null) return;

    const newBoard = [...board];
    for (let row = board.length - 1; row >= 0; row--) {
      if (newBoard[row][col] === null) {
        newBoard[row][col] = player;
        setBoard(newBoard);
        setPlayer(player === 1 ? 2 : 1);
        break;
      }
    }
  };

  const resetGame = () => {
    setBoard(Array.from({ length: 6 }, () => Array(7).fill(null)));
    setPlayer(1);
    setWinner(null);
  };

  return (
    <div className="game">
      <h1>Connect Four</h1>
      <Board board={board} insertCoin={insertCoin} player={player} />
      <div className="status">
        {winner && winner !== "tie" && <p>Player {winner} wins!</p>}
        {winner && winner === "tie" && <p>It's a tie!</p>}
        {!winner && <p>Player {player}'s turn</p>}
      </div>
      <button className="reset-btn" onClick={resetGame}>
        Reset Game
      </button>
    </div>
  );
};

export default Game;
