import React, { useState } from "react";

const Cell = ({ value, onClick, hoverable, player }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`cell ${hoverable ? "hoverable" : ""}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && hoverable ? (
        player == 1 ? (
          <i className="fa-solid fa-circle red"></i>
        ) : (
          <i className="fa-solid fa-circle blue"></i>
        )
      ) : null}
      {value === 1 ? (
        <i className="fa-solid fa-circle red"></i>
      ) : value === 2 ? (
        <i className="fa-solid fa-circle blue"></i>
      ) : null}
    </div>
  );
};

export default Cell;
