import React from "react";
import Cell from "./Cell";

const Board = ({ board, insertCoin, player }) => {
  return (
    <div className="board">
      {board.map((row, rowIndex) => (
        <div key={rowIndex} className="row">
          {row.map((cell, colIndex) => (
            <Cell
              key={colIndex}
              value={cell}
              onClick={rowIndex === 0 ? () => insertCoin(colIndex) : null}
              hoverable={rowIndex === 0}
              player={player}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default Board;
